import React, { useState, useEffect } from 'react';
import classes from './Grid.module.css';
import images from '../../imageMapping';

function Grid(props) {
  const [gameHasStarted, setGameHasStarted] = useState(true);
  const { towerPlaced, setTowerPlaced, sideLength, numberOfRocks, setLevelComplete, setlevelNumber, levelNumber, setElapsedTime, setLevelClearedScreen, stopTimer, elapsedTime, barFill, setBarFill, fireActive, setFireActive } = props;
  const { maxWaterStorage, range, money, setMoney, autoSeeder, fireService } = props;
  const [everythingDead, setEverythingDead] = useState(false);
  const [days, setDays] = useState(0);
  const [imageKey, setImageKey] = useState(0);
  const sideLengthSquared = sideLength * sideLength;
  const [growthRate, setGrowthRate] = useState(0);
  const [passed30, setPassed30] = useState(false);

  const [firstWater, setFirstWater] = useState(false);
  const [firstWaterDone, setFirstWaterDone] = useState(false);
  const [firstFire, setFirstFire] = useState(false);
  const [firstFireDone, setFirstFireDone] = useState(false);

  const [localWaterStorage, setLocalWaterStorage] = useState(maxWaterStorage);
  const [waterIcons, setWaterIcons] = useState([]);
  const [localMoneyCount, setLocalMoneyCount] = useState(0);

  let intervalId; // Define intervalId here

  // Generate an array of cells with unique identifiers
  function generateInitialCells() {
    const initialCells = Array.from({ length: sideLengthSquared }, (_, index) => ({
      id: `cell_${index + 1}`, // Unique identifier for each cell
      turnsWithoutGrowth: 0,
      value: 0, // Adding 'value' property to each cell
    }));
  
    // Randomly select two cells
    const randomCellIndices = [];
    while (randomCellIndices.length < numberOfRocks) {
      const randomIndex = Math.floor(Math.random() * sideLengthSquared);
      if (!randomCellIndices.includes(randomIndex) && Math.floor(randomIndex / sideLength) !== 0 && Math.floor(randomIndex / sideLength) !== sideLength - 1 && randomIndex % sideLength !== 0 && randomIndex % sideLength !== sideLength -1) {
        randomCellIndices.push(randomIndex);
      }
    }
    // Set the value of the selected cells to rock
    randomCellIndices.forEach(index => {
      initialCells[index].value = -1;
    });

    // Randomly selects all remaining cell
    const randomTreeIndices = [];
    while (randomTreeIndices.length < sideLengthSquared - numberOfRocks - sideLength*2) {
      const randomIndex = Math.floor(Math.random() * sideLengthSquared);
      if (!randomTreeIndices.includes(randomIndex) && initialCells[randomIndex].value !== -1) {
        randomTreeIndices.push(randomIndex);
      }
    }
    // Set the value of the selected cells to tree
    randomTreeIndices.forEach(index => {
      initialCells[index].value = Math.floor(Math.random() * 13) + 6;
    });
    
  
    return initialCells;
  }
  useEffect(() => {
    setCells(generateInitialCells());
}, [numberOfRocks, sideLength]);

  const [cells, setCells] = useState(generateInitialCells());

  const handleCellClick = (id) => {
    const clickedCellIndex = cells.findIndex((cell) => cell.id === id);
    const updatedCells = [...cells];

    if(!towerPlaced && cells[clickedCellIndex].value !== -1){
        updatedCells[clickedCellIndex].value = 21; // Update to -3 immediately
        updatedCells[clickedCellIndex] = { ...updatedCells[clickedCellIndex], value: 21, turnsWithoutGrowth: 0 };
        setCells(updatedCells);
        setTowerPlaced(true);
    }

    if(towerPlaced && cells[clickedCellIndex].value === 0){
        updatedCells[clickedCellIndex].value = 20; // Update to -3 immediately
        updatedCells[clickedCellIndex] = { ...updatedCells[clickedCellIndex], value: 20, turnsWithoutGrowth: 0 };
        setCells(updatedCells);  
    }
  
    // Looks to see if cell is in range of tower
    const isWithinOneCellRangeOfValue21 = (row, col) => {
      // Define the highlighted neighbors
      const highlightedNeighbors = [
        { row: -1, col: -1 },  // TopLeft
        { row: -1, col: 1 },   // TopRight
        { row: -1, col: 0 },   // Top
        { row: 1, col: 0 },    // Bottom
        { row: 1, col: -1 },   // BottomLeft
        { row: 1, col: 1 },    // BottomRight
        { row: 0, col: -1 },   // Left
        { row: 0, col: 1 }     // Right
      ];

      // Conditionally add the second array of coordinates based on the value of range
      if (range > 1) {
          highlightedNeighbors.push(
              { row: -2, col: 0 },   // TopTop
              { row: 2, col: 0 },    // BottomBottom
              { row: 0, col: -2 },   // LeftLeft
              { row: 0, col: 2 }     // RightRight
          );
      }

      if (range > 2) {
        highlightedNeighbors.push(
            { row: -2, col: -1 },
            { row: -2, col: +1 },
            { row: -1, col: -2 },
            { row: +1, col: -2 },
            { row: 2, col: -1 },
            { row: 2, col: +1 },
            { row: -1, col: +2 },
            { row: +1, col: +2 },
        );
      }

      if (range > 3) {
        // Include every index in the grid
        for (let r = -sideLength; r <= sideLength; r++) {
            for (let c = -sideLength; c <= sideLength; c++) {
                if (r !== 0 || c !== 0) { // Exclude the current cell
                    highlightedNeighbors.push({ row: r, col: c });
                }
            }
        }
    }
  
      // Iterate over the highlighted neighbors
      for (const neighbor of highlightedNeighbors) {
          const newRow = row + neighbor.row;
          const newCol = col + neighbor.col;
          // Check if the neighbor is within the grid bounds
          if (newRow >= 0 && newRow < sideLength && newCol >= 0 && newCol < sideLength) {
              // Check if the neighbor cell has the value 21
              if (updatedCells[newRow * sideLength + newCol].value === 21) {
                  return true;
              }
          }
      }
      return false;
  };


  if (updatedCells[clickedCellIndex].value === 43) {
    if(firstWater){
      setFirstWaterDone(true);
      setFirstWater(false);
    };
    setLocalWaterStorage(prevLocalWaterStorage => prevLocalWaterStorage + 1);
    updatedCells[clickedCellIndex].value = 8; // Update to -3 immediately
    updatedCells[clickedCellIndex] = { ...updatedCells[clickedCellIndex], value: 12, turnsWithoutGrowth: 0 };
    setMoney(money+20);
    setLocalMoneyCount(prevLocalMoneyCount => prevLocalMoneyCount + 20);
    setCells(updatedCells); // Update state immediately
  }

  // Puts out fire if it is in range
  if (updatedCells[clickedCellIndex].value === -2) {
    const clickedRow = Math.floor(clickedCellIndex / sideLength);
    const clickedCol = clickedCellIndex % sideLength;
    if (isWithinOneCellRangeOfValue21(clickedRow, clickedCol) && localWaterStorage > 0) {
      updatedCells[clickedCellIndex].value = -3; // Update to -3 immediately
      updatedCells[clickedCellIndex] = { ...updatedCells[clickedCellIndex], value: -3, turnsWithoutGrowth: 0 };
      setCells(updatedCells); // Update state immediately
      setLocalWaterStorage(prevLocalWaterStorage => prevLocalWaterStorage - 1);
      setMoney(money + 100);
      setLocalMoneyCount(prevLocalMoneyCount => prevLocalMoneyCount + 100);
    } else {
    }
  }

    setCells(updatedCells);
  };

  useEffect(() => {
    const updateCells = () => {
      toggleImageKey();
      setCells(prevCells => {
        const updatedCells = prevCells.map(cell => ({ ...cell }));
        const referenceCells = JSON.parse(JSON.stringify(prevCells));

        const anyAliveCell = updatedCells.some(cell => cell.value > 0);
        if(anyAliveCell){setDays(prevDays => prevDays + 1);};
  
        updatedCells.forEach((cell, index) => {
          const row = Math.floor(index / sideLength);
          const column = index % sideLength;
          let shouldDecrement = true;
  
          if (cell.value === -1) return;

          // Definies neighbour cells of fire and aging trees (for shedding seeds)
          if (cell.value === -2 || cell.value === 4 || cell.value === 5) {
            // Define the relative positions of neighboring cells
            const relativeNeighbors = [
              { row: -1, col: -1 },  // TopLeft
              { row: -1, col: +1 },  // TopRight
              { row: -1, col: 0 },  // Top
              { row: 1, col: 0 },   // Bottom
              { row: 1, col: -1 },   // BottomLeft
              { row: 1, col: +1 },   // BottomRight
              { row: 0, col: -1 },  // Left
              { row: 0, col: 1 }    // Right
            ];

            // Iterate over relative neighbor positions
            relativeNeighbors.forEach(neighborPos => {
              const neighborRow = row + neighborPos.row;
              const neighborCol = column + neighborPos.col;

              // Check if the neighbor position is within the grid bounds
              if (neighborRow >= 0 && neighborRow < sideLength && neighborCol >= 0 && neighborCol < sideLength) {
                const neighborIndex = neighborRow * sideLength + neighborCol;
                const neighborCell = updatedCells[neighborIndex];
                
                // Check to see if a cell is empty first, and sets a probability it will catch fire
                if (neighborCell.value > 0 && neighborCell.value !== 21 && cell.value === -2 && Math.floor(Math.random() * 10) + 1 === 1) {
                  // Set it on fire
                  if (neighborCell.value === 43){
                    neighborCell.value = -3;
                  }
                  else{
                    neighborCell.value = -2;
                  }
                }
                // Otherwise it must be a tree seed, and set a probability it will get planted
                else if(neighborCell.value === 0 && cell.value != -2 && Math.floor(Math.random() * 2) + 1 === 1){
                  neighborCell.value = 20;
                }
              }
            });
          }
          // Makes trees 'grow' each interval
          if (shouldDecrement && cell.value > 0 && cell.value < 21) {
            cell.value -= 1;
          }
          
          // Puts out fire if it has been around for 3 intervals
          if (cell.value === referenceCells[index].value && cell.value !== 21) {
            cell.turnsWithoutGrowth += 1;
          } else {
            cell.turnsWithoutGrowth = 0;
          }
          if(cell.value === 43 && cell.turnsWithoutGrowth === 2){
            cell.value = 0;
          }
          if (cell.turnsWithoutGrowth > 3) {
            cell.value = 0;
          }

          // autoSeeder
          if (cell.value === 0 && autoSeeder && Math.floor(Math.random() * 3) + 1 === 1){
            updatedCells[index].value = 20; // Update to -3 immediately
            updatedCells[index] = { ...updatedCells[index], value: 20, turnsWithoutGrowth: 0 };
          }
          // fireService
          const isWithinOneCellRangeOfValue21 = (row, col) => {
            // Define the highlighted neighbors
            const highlightedNeighbors = [
              { row: -1, col: -1 },  // TopLeft
              { row: -1, col: 1 },   // TopRight
              { row: -1, col: 0 },   // Top
              { row: 1, col: 0 },    // Bottom
              { row: 1, col: -1 },   // BottomLeft
              { row: 1, col: 1 },    // BottomRight
              { row: 0, col: -1 },   // Left
              { row: 0, col: 1 }     // Right
            ];
      
            // Conditionally add the second array of coordinates based on the value of range
            if (range > 1) {
                highlightedNeighbors.push(
                    { row: -2, col: 0 },   // TopTop
                    { row: 2, col: 0 },    // BottomBottom
                    { row: 0, col: -2 },   // LeftLeft
                    { row: 0, col: 2 }     // RightRight
                );
            }
      
            if (range > 2) {
              highlightedNeighbors.push(
                  { row: -2, col: -1 },
                  { row: -2, col: +1 },
                  { row: -1, col: -2 },
                  { row: +1, col: -2 },
                  { row: 2, col: -1 },
                  { row: 2, col: +1 },
                  { row: -1, col: +2 },
                  { row: +1, col: +2 },
              );
            }
      
            if (range > 3) {
              // Include every index in the grid
              for (let r = -sideLength; r <= sideLength; r++) {
                  for (let c = -sideLength; c <= sideLength; c++) {
                      if (r !== 0 || c !== 0) { // Exclude the current cell
                          highlightedNeighbors.push({ row: r, col: c });
                      }
                  }
              }
          }
        
            // Iterate over the highlighted neighbors
            for (const neighbor of highlightedNeighbors) {
                const newRow = row + neighbor.row;
                const newCol = col + neighbor.col;
                // Check if the neighbor is within the grid bounds
                if (newRow >= 0 && newRow < sideLength && newCol >= 0 && newCol < sideLength) {
                    // Check if the neighbor cell has the value 21
                    if (updatedCells[newRow * sideLength + newCol].value === 21) {
                        return true;
                    }
                }
            }
            return false;
        };
          if (fireService && isWithinOneCellRangeOfValue21(row, column) && cell.value === -2) {
            cell.value = -3;
            setLocalWaterStorage(prevLocalWaterStorage => prevLocalWaterStorage - 1);
            setMoney(money + 50);
            setLocalMoneyCount(prevLocalMoneyCount => prevLocalMoneyCount + 50);
          }
        });

        // Check if majority of cells are empty, and if so start seeding
        const emptyCells = updatedCells.filter(cell => cell.value === 0);
        
        if (emptyCells.length > 0 && sideLengthSquared / emptyCells.length < 2) {
          const randomCellIndices = [];
          while (randomCellIndices.length < 1) {
            const randomIndex = Math.floor(Math.random() * sideLengthSquared);
            if (!randomCellIndices.includes(randomIndex) && updatedCells[randomIndex].value === 0) {
              randomCellIndices.push(randomIndex);
            }
          }
          // Set the value of the selected cells to seed
          randomCellIndices.forEach(index => {
            updatedCells[index].value = 20;
          });
        }
        

        // Drops water
        if (localWaterStorage < maxWaterStorage && Math.floor(Math.random() * 4) + 1 === 1){
          const randomCellIndices = [];
          while (randomCellIndices.length < 1) {
            const randomIndex = Math.floor(Math.random() * sideLengthSquared);
            if (!randomCellIndices.includes(randomIndex) && updatedCells[randomIndex].value !== 21 && updatedCells[randomIndex].value !== -1) {
              if(!firstWaterDone && sideLength < 5){
                setFirstWater(true);
              }
              randomCellIndices.push(randomIndex);
            }
          }
          // Set the value of the selected cells to seed
          randomCellIndices.forEach(index => {
            updatedCells[index].value = 43;
          });
        }  
        const cellsWithValueInRange = updatedCells.filter(cell => cell.value > 0 && cell.value < 17);
        const numberOfCellsWithValueInRange = (sideLengthSquared - numberOfRocks - 1) / cellsWithValueInRange.length;
        // Applies bar fill or unfill
        if(!fireActive && numberOfCellsWithValueInRange < 1.5){
          setBarFill(prevBarFill => prevBarFill + growthRate);
          setMoney(prevMoney => prevMoney + 10);
          setLocalMoneyCount(prevLocalMoneyCount => prevLocalMoneyCount + 20);
        }
        return updatedCells;
      });
    };
  
    // Start the continuous update when all seeds are planted
    if (towerPlaced && !firstFire && !firstWater) {
      // Start interval only if it's not already started
      if (!intervalId) {
        intervalId = setInterval(updateCells, 2000);
      }
    }
  
    // Cleanup function
    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [everythingDead, towerPlaced, fireActive, firstFire, maxWaterStorage, localWaterStorage, firstWater]);

// Starts a fire
  useEffect(() => {
    const towers = cells.filter(cell => cell.value === 21);
    if (days > 12 && towers.length > 0 && !fireActive) {
      if (sideLength === 4 && !firstFire && !firstFireDone) {
        setFirstFire(true);
        setFirstFireDone(true);
        setTimeout(() => {
          setFirstFire(false); // Set firstFire back to false after 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds
      }
      const validCells = cells.filter(cell => cell.value > 0 && cell.value < 18);
      if (validCells.length > 0) {
        const randomIndex = Math.floor(Math.random() * validCells.length);
        const randomCell = validCells[randomIndex];
        randomCell.value = -2;
        setDays(0);
      }
    }
  }, [days, cells]);


// Win condition
  useEffect(() => {
    if (barFill>100) {
        setLevelComplete(true);
        stopTimer();
        setLevelClearedScreen(true);
        setElapsedTime(0);
        setFireActive(false);
        if(levelNumber < sideLength -2){
          setlevelNumber(sideLength - 2);
        }
    }
  }, [barFill]);

// Add a second lookout tower halfway
  useEffect(() => {
    if (barFill>20 || firstFireDone){
      setPassed30(true);
    }
  }, [barFill]);


// Sets fire active... if theres a fire
  useEffect(() => {
    const fires = cells.filter(cell => cell.value === -2);
    if (fires.length > 0) {
      setFireActive(true);
    }
    else{
      setFireActive(false);
    }
  }, [cells]);

// Determines how fast the bar fills
useEffect(() => {
  let mutiplier; // Declare mutiplier outside the if...else block

  if (sideLength === 4) {
    mutiplier = 3;
  } else {
    mutiplier = 2.4;
  }
  // Adds to the bar if there isn't a fire. Bar fills quicker if there are more healthy trees
    setGrowthRate(1 * mutiplier);
}, [cells, sideLength, sideLengthSquared]);


  
// Animation trigger
  const toggleImageKey = () => {
    setImageKey(prevKey => prevKey === 0 ? 1 : 0);
  };

// Set water storage icon number
  useEffect(() => {
    const icons = [];
    for (let i = 0; i < localWaterStorage; i++) {
      // Check if the current index is within the range of images to render
      icons.push(<img key={i} src={images[44]} />);
    }
    setWaterIcons(icons);
  }, [localWaterStorage]);

  useEffect(() => {
    if(localWaterStorage>maxWaterStorage){
      setLocalWaterStorage(maxWaterStorage);
    }
  }, [localWaterStorage]);



  const abandon = () => {
    setLevelComplete(true);
    stopTimer();
    setElapsedTime(0);
    setFireActive(false);
    setMoney(money-localMoneyCount);
    setTowerPlaced(false);
  };
  


  return (
    <div className={classes.Main}>
      <div className={classes.Container}>
        {cells.map((cell, index) => (
          <div
            key={cell.id}
            className={`${classes.Cell} ${cell.value > 0 || cell.value === -1 || cell.value === -2 ? classes.Clicked : ''} ${cell.value !== 21 && cell.value !== -1 &&(() => {
              // Inlining the logic here
              // Calculate the central row and column
              const centralRow = Math.floor(index / sideLength);
              const centralCol = index % sideLength;

              // Define the highlighted neighbors
              const highlightedNeighbors = [
                { row: -1, col: -1 },  // TopLeft
                { row: -1, col: 1 },   // TopRight
                { row: -1, col: 0 },   // Top
                { row: 1, col: 0 },    // Bottom
                { row: 1, col: -1 },   // BottomLeft
                { row: 1, col: 1 },    // BottomRight
                { row: 0, col: -1 },   // Left
                { row: 0, col: 1 }     // Right
              ];
        
              // Conditionally add the second array of coordinates based on the value of range
              if (range > 1) {
                  highlightedNeighbors.push(
                      { row: -2, col: 0 },   // TopTop
                      { row: 2, col: 0 },    // BottomBottom
                      { row: 0, col: -2 },   // LeftLeft
                      { row: 0, col: 2 }     // RightRight
                  );
              }

              if (range > 2) {
                highlightedNeighbors.push(
                    { row: -2, col: -1 },
                    { row: -2, col: +1 },
                    { row: -1, col: -2 },
                    { row: +1, col: -2 },
                    { row: 2, col: -1 },
                    { row: 2, col: +1 },
                    { row: -1, col: +2 },
                    { row: +1, col: +2 },
                );
              }

              if (range > 3) {
                // Include every index in the grid
                for (let r = -sideLength; r <= sideLength; r++) {
                    for (let c = -sideLength; c <= sideLength; c++) {
                        if (r !== 0 || c !== 0) { // Exclude the current cell
                            highlightedNeighbors.push({ row: r, col: c });
                        }
                    }
                }
            }

              // Iterate over the highlighted neighbors
              for (const neighbor of highlightedNeighbors) {
                  const row = centralRow + neighbor.row;
                  const col = centralCol + neighbor.col;
                  // Check if the neighbor is within the grid bounds
                  if (row >= 0 && row < sideLength && col >= 0 && col < sideLength) {
                      // Check if the neighbor cell has the value 21
                      if (cells[row * sideLength + col] && cells[row * sideLength + col].value === 21) {
                          return true;
                      }
                  }
              }

              return false;

            })() ? classes.ClickedLookOut : ''}`}
            style={{ // Apply inline styles for height and width
              height: `${(100 / sideLength) * 0.9}%`,
              width: `${(100 / sideLength) * 0.9}%`
            }}
            onClick={() => handleCellClick(cell.id)}
          >
            {cell.value && images[cell.value] ? 
              <img
                key={cell.value !== -1 && cell.value !== 21 ? `${imageKey}_${cell.id}` : null} // Unique key for each image
                src={images[cell.value]}
                alt={`cell_${cell.value}`}
                className={cell.value !== -1 && cell.value !== 21 ? classes.AnimatedImages : null}
              />: null}
          </div>
        ))}
      </div>
      <div className={classes.InfoContainer}>
      {towerPlaced ? 
      <div>
        <div className={fireActive ? classes.TopLightFire : classes.TopLight}>
          <div className={fireActive ? classes.HorizontalLineFire : classes.HorizontalLine} style={{ width: `${barFill}%` }}></div>
        </div>
        <div className={classes.WaterStorage}>
          <div>{waterIcons}</div> 
          <h1>${money}</h1>
        </div>
      </div> 
      : <><h1 className={classes.Instruction}>tap a patch or tree<br/>to build a lookout</h1></>}
      {towerPlaced && sideLength===4 && barFill > 1 && barFill < 15 && !firstFire ?
      <><h1 className={classes.InstructionSecondary}>tap plots to plant seeds<br/>in empty plots</h1></> 
      : sideLength===4 && barFill > 18 && barFill < 40 && !firstFire && !firstWater ? <><h1 className={classes.InstructionSecondary}>if the forest is healthy<br/>the bar will fill</h1></>
      : barFill > 70 && sideLength < 5 && !firstWater ? <><h1 className={classes.InstructionSecondary}>earn money by<br/>putting out fires</h1></> : 
      null}
      {firstFire ? <><h1 className={classes.InstructionSecondary}>look out for fire<br/>tap fire in range of towers</h1></> : null}
      {firstWater ? <><h1 className={classes.InstructionSecondary}>tap on rainclouds<br/>to fill the water tank</h1></> : null}
      {sideLength>4 && towerPlaced ? <><h1 className={classes.InstructionSecondaryButton} onClick={abandon}>abandon patch (-${localMoneyCount})</h1></> : null}
      </div>
    </div>
  );
  
}

export default Grid;
